*,
*::before,
*::after {
  box-sizing: border-box;
}

@import 'common/index';
@import 'components/index';

body,
html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  font: 500 18px/21px 'Gilroy', sans-serif;
  // font-family: 'Nunito', sans-serif;
  color: #fff;

  &::before {
    content: '';
    /* background: #171a21 url('../../assets/img/bg.png'); */
    background-color: #311e29;
    background-image: linear-gradient(43deg, #311e29 0%, #3c242f 20%, #531c30 54%, #47303d 83%, #343542 100%);
    background-size: cover;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
  }

  &.second {
    &::before {
      /* background: #171a21 url('../../assets/img/bg2.png'); */
      background-color: #311e29;
      background-image: linear-gradient(43deg, #311e29 0%, #3c242f 20%, #531c30 54%, #47303d 83%, #343542 100%);
      background-size: cover;
    }
  }
}

.container {
  max-width: 1400px;
  padding: 10px 25px;
  margin: 0 auto;
}

.section-about {
  max-width: 500px;
}

.section-settings {
  max-width: 500px;
}
