.input-select-container {
    position: relative;
    /* width: 250px; */
  }
  
  .input-select {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: text;
    outline: none;
    min-height: 24px;
  }
  
  .input-select:focus {
    border-color: #FFF;
  }
  
  .options-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #1D2129;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    max-height: 150px;
    overflow-y: auto;
    z-index: 1000;
  }
  
  .option {
    padding: 8px;
    cursor: pointer;
  }
  
  .option:hover {
    background-color: #000;
  }
  
  .no-options {
    color: #888;
    text-align: center;
  }
  