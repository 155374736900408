.dropzoneContainer {
  margin-top: 10px;
  width: 100%;
  overflow: hidden;
}

.dropzoneFileName {
  margin-top: 10px;
  width: 100%;
  overflow: hidden;
}

.dropzone {
  margin-top: 10px;
  padding: 10px;
  border-color: #e7e4da;
  border-radius: 10px;
  border-style: dashed;
  border-width: 1px;
  min-height: 50px;
  width: 100%;
  text-align: center;
}
.dropzone div,
.dropzone button {
  margin: 5px;
}
