* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: 'Nunito', sans-serif; */
}
body {
  background: #4c236f;
}
#container {
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.main_block {
  width: auto;
  max-width: 1200px;
  height: auto;
  max-height: 600px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
.payment_info {
  width: auto;
  max-width: 350px;
  height: auto;
  padding: 20px 35px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  border-radius: 10px 0 0 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  background: linear-gradient(328deg, #0d0b20 0%, #37225b 50%, #3c6384 100%);
}
p {
  font-size: 14px;
  font-weight: 400;
  color: #92979d;
}
.hr {
  width: 150px;
  height: 1px;
  background-color: #92979d48;
  margin: 40px 0;
  border-radius: 1px;
}
.payment_box {
  width: auto;
  max-width: 400px;
  height: auto;
  padding: 20px 35px;
  border-radius: 0 10px 10px 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  background: #161b21;
}
.methods {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 20px 0;
  flex-wrap: wrap;
}
.method {
  width: 100px;
  height: 70px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #22292f;
  margin: 5px 5px;
}
.method:hover {
  opacity: 0.9;
}
.method:active {
  transform: scale(0.95);
}
.method_active {
  background: linear-gradient(328deg, #fcb02a 0%, #e56534 100%);
  border: 1px solid #fcb02a;
}
.method img {
  width: auto;
  height: 55px;
  object-fit: contain;
}
.input {
  background: #212a33;
  border-radius: 5px;
  border: 0px solid #fcb02a;
  padding: 15px;
  color: white;
  margin-top: 15px;
  width: 100%;
}
.input::placeholder {
  color: #92979d;
}
.input:focus {
  outline: none;
  border: 1px solid #333c45;
}

.button {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  display: flex;
  padding: 0 10px;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, #7266ee 0%, #473f70 100%);
  margin-top: 15px;
  cursor: pointer;
  text-transform: uppercase;
}
.button:hover {
  opacity: 0.9;
}
.button:active {
  transform: scale(0.95);
}
.copyright {
  margin-top: 65px;
}
.img_filter {
  filter: invert(100%) sepia(94%) saturate(22%) hue-rotate(39deg)
    brightness(106%) contrast(107%);
}
.blur_bg {
  position: absolute;
  width: 100%;
  height: 100vh;
  /* background: url('../public/bg.png'); */
  background-size: cover;
  background-position: center;
  filter: blur(20px);
  z-index: -1;
}
.page_box {
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background: #161b21;
}

/* !! Тут мои стили */

.left_block {
  padding: 50px 45px 130px 45px;
  width: 440px;
  height: 620px;
  background: #ffffff;
  border-radius: 20px;
}

.right_block {
  width: 509.67px;
  height: 500px;
  left: 925.16px;
  top: 225px;

  background: linear-gradient(171.39deg, #234a83 0.5%, #330e41 99.36%), #ffffff;
  border-radius: 0px 20px 20px 0px;
}

.title {
  font-weight: 600;
  font-size: 28px;
  line-height: 33px;

  color: #000000;
  text-align: center;
}

.title_input {
  color: black;
  font-family: "Gilroy";
  font-style: normal;

  font-size: 16px;
  font-weight: bold;
}

.custom-input-mt35 {
  margin-top: 35px !important;
  border-bottom: 1px solid rgb(0 0 0 / 50%) !important;
  color: black !important;
  line-height: 0px !important;
}

.input_error {
  border-bottom: 1px solid red !important;
}

.auth_input::placeholder {
  color: rgba(0, 0, 0, 0.5) !important;
}

.btn-auth {
  margin: 70px auto 0 auto !important;
  width: 205px !important;
  height: 60px !important;
  font-size: 20px !important;
  display: block;
  border-radius: 10px !important;
}

.right_block {
  /* padding: 70px 90px 95px 60px; */
  padding: 30px 90px 95px 60px;
}

.title_desc {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 43px;
  color: #ffffff;
}

.title_desc-grey {
  margin-top: 20px;
  margin-bottom: 50px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
  font-size: 20px;
}

.btn-auth-registration {
  width: 285px;
  height: 75px;
  background: #ffffff !important ;
  border-radius: 10px;
  color: #000000 !important;
  font-weight: 600;
  border-radius: 10px !important;
  font-size: 22px !important;
}

.btn-auth-registration:hover {
  background: #d0caca !important ;
  color: #000000 !important;
}

.logo {
  font: 700 48px/58px "Gilroy", sans-serif;
}

@media screen and (max-width: 750px) {
  .main_block {
    flex-direction: column;
  }
  .payment_info {
    display: none;
  }
  .payment_box {
    border-radius: 10px;
    max-width: 600px;
  }
  .amount_in_box {
    display: block !important;
  }
}
.payment_details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.btn-dn {
  display: none;
  width: 205px;
  margin: 0 auto;
  padding: 0;
  background: #ffffff !important;
  color: black !important;
  height: 42px;
  border: 1px solid black !important;
}

@media (max-width: 1000px) {
  /* .main_block {
      flex-direction: column;
    } */
  .btn-dn {
    display: block;
  }

  .btn-auth {
    margin: 20px auto !important;
  }

  .right_block {
    display: none;
    width: 440px;
    border-radius: 20px;
    margin-top: 30px;
  }
}

@media (max-width: 460px) {
  .main_block {
    margin-top: 25px;
    margin-bottom: 25px;
    max-width: 100%;
    flex-direction: column;
  }
  .right_block {
    width: 440px;
    border-radius: 0px;
    margin-top: 0px;
    width: 100%;
  }
  .left_block {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  #container {
    align-items: flex-start;
  }
  .btn-auth-registration {
    width: 100%;
  }
  .title_desc {
    font-size: 25px;
  }
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}