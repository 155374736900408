.pagination {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  gap: 8px;

  button {
    width: 32px;
    height: 32px;
    cursor: pointer;
    font: 600 14px/20px 'Gilroy', sans-serif;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    background-color: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(223, 227, 232, 0.2);
    border-radius: 4px;
    transition: .2s ease background-color;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }

    &:disabled {
      cursor: default;
      background-color: rgba(255, 255, 255, 0.2) !important;

      &:before {
        opacity: .4;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }

  &-active {
    background-color: rgba(255, 255, 255, 0.4) !important;
    border-color: #fff !important;
    color: #fff !important;
  }

  &-prev:before,
  &-next:before {
    content: '';
    width: 9px;
    height: 13px;
    background-size: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &-disabled {
    cursor: default;
    background-color: rgba(255, 255, 255, 0.2) !important;

    &:before {
      opacity: .4;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }

  &-prev {
    position: relative;

    &:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='13' viewBox='0 0 9 13' fill='none' %3E%0A%3Cpath d='M8.15991 1.91415L3.57991 6.50415L8.15991 11.0942L6.74991 12.5042L0.749912 6.50415L6.74991 0.50415L8.15991 1.91415Z' fill='white'/%3E%0A%3C/svg%3E%0A");
    }
  }

  &-next {
    position: relative;

    &:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='13' viewBox='0 0 9 13' fill='none' %3E%0A%3Cpath d='M0.840088 1.91415L5.42009 6.50415L0.840088 11.0942L2.25009 12.5042L8.25009 6.50415L2.25009 0.50415L0.840088 1.91415Z' fill='white'/%3E%0A%3C/svg%3E%0A");
    }
  }
}