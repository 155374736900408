@font-face {
  font-family: 'Gilroy';
  font-weight: 400;
  src: url(../../assets/fonts/Gilroy-Regular.ttf);
}

@font-face {
  font-family: 'Gilroy';
  font-weight: 500;
  src: url(../../assets/fonts/Gilroy-Medium.ttf);
}

@font-face {
  font-family: 'Gilroy';
  font-weight: 600;
  src: url(../../assets/fonts/Gilroy-SemiBold.ttf);
}

@font-face {
  font-family: 'Gilroy';
  font-weight: 700;
  src: url(../../assets/fonts/Gilroy-Bold.ttf);
}
