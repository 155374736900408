.main {
  display: inline-block;
  margin-left: 70px;
  margin-bottom: 70px;
  vertical-align: top;
  width: calc(100% - 360px);

  @media (max-width: 1400px) {
    margin-left: 0;
    width: 100%;
  }

  &__head {
    display: flex;
    align-items: center;
    gap: 25px;
    margin-bottom: 45px;

    @media (max-width: 460px) {
      flex-direction: column-reverse;
      align-items: start;
      gap: 10px;
      margin-bottom: 20px;
    }

    &-back {
      cursor: pointer;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &:hover {
        svg {
          transform: translateX(-2px);
        }
      }

      svg {
        margin-right: 10px;
        transition: 0.2s ease transform;
      }
    }
  }
}
