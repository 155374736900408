.custom-input {
  display: flex;
  flex-direction: column;
  gap: 0px;
  margin-top: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  transition: .2s ease border-color;

  @media (max-width: 475px) {
    flex-direction: column;
    gap: 0;
    margin-top: 10px;
  }

  &:has(input:focus) {
    border-color: #fff;
  }

  &_block {
    flex-direction: column;
    gap: 0;
    position: relative;

    label {
      margin-bottom: 5px !important;
    }

    input {
      padding: 10px 0 !important;
    }

    &-view {
      margin-top: 5px;
    }

    &-btn {
      position: absolute;
      right: 0;
      bottom: 10px;
      font-weight: 500;
      font-size: 13px;
      line-height: 15px;
    }
  }

  input {
    flex: 2;
    padding-top: 10px;
    padding-bottom: 10px;
    background: transparent;
    border: none;
    outline: none;
    font-family: 'Gilroy', sans-serif;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;

    &::placeholder {
      color: rgb(255 255 255 / 50%);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #262b35 inset;
      -webkit-text-fill-color: #fff;
      padding-left: 10px !important;
      margin-top: 5px;
    }

    @media (max-width: 475px) {
      padding: 10px 0;
    }
  }

  select {
    flex: 2;
    padding-top: 10px;
    padding-bottom: 10px;
    background: transparent;
    border: none;
    outline: none;
    font-family: 'Gilroy', sans-serif;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;

    &::placeholder {
      color: rgb(255 255 255 / 50%);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #262b35 inset;
      -webkit-text-fill-color: #fff;
      padding-left: 10px !important;
      margin-top: 5px;
    }

    @media (max-width: 475px) {
      padding: 10px 0;
    }
  }

  label {
    margin: 10px 0;
  }
}

.custom-checkbox {
  width: 20px;
  height: 20px;
  position: relative;
  display: inline-block;
  border: 2px solid #fff;

  &:after {
    content: '';
    position: absolute;
    left: 2px;
    top: 4px;
    width: 12px;
    height: 10px;
    opacity: 0;
    transition: .1s ease opacity;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='10' viewBox='0 0 12 10' fill='none' %3E%0A%3Cpath d='M4.33317 9.08752L0.166504 4.95419L1.4915 3.67086L4.33317 6.46252L10.5082 0.337524L11.8332 1.65419L4.33317 9.08752Z' fill='white'/%3E%0A%3C/svg%3E%0A");
  }

  &:has(input:checked) {
    &:after {
      opacity: 1;
    }
  }

  input {
    display: none;
  }
}

.custom-switch {
  display: flex;

  input {
    display: none;

    &:checked + label {
      &::before {
        background: rgba(116, 205, 93, 0.8);
      }

      &::after {
        transform: translateX(20px);
      }
    }
  }

  label {
    cursor: pointer;
    position: relative;

    &::before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      margin-right: 15px;
      background-color: #c03838;
      border-radius: 50px;
      width: 50px;
      height: 30px;
      transition: 0.2s ease background-color;
    }

    &::after {
      content: "";
      position: absolute;
      width: 24px;
      height: 24px;
      background: #ffffff;
      border-radius: 50px;
      left: 3px;
      top: 3px;
      transition: 0.2s ease transform;
    }
  }
}

.select-btn {
  font: 500 16px/19px 'Gilroy', sans-serif;
  color: #fff;
  position: relative;
  opacity: .5;
  cursor: pointer;
  transition: .2s ease opacity;
  border: none;
  background: transparent;
  text-align: left;
  width: 200px;
  padding: 0;
  margin-top: 15px;

  &:hover {
    opacity: 1;
  }

  &:after {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='10' viewBox='0 0 15 10' fill='none' %3E%0A%3Cpath d='M1.66211 1.37708L7.91602 7.63123L14.1702 1.37708' stroke='white' stroke-width='2'/%3E%0A%3C/svg%3E%0A");
    width: 15px;
    height: 10px;
    background-repeat: no-repeat;
    background-size: 100%;
    display: inline-block;
    margin-left: 10px;
    position: relative;
    top: -2px;
  }
}

.select-options {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 30px 0 0;
  padding: 0;
  list-style: none;
  font: 500 16px/19px 'Gilroy', sans-serif;
  color: #fff;

  .select-option {
    position: relative;
    opacity: .5;
    cursor: pointer;
    transition: .2s ease opacity;

    &:hover {
      opacity: .8;
    }

    &:after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='11' viewBox='0 0 16 11' fill='none' %3E%0A%3Cpath d='M1.31152 3.74737L6.76855 9.20452L15.1818 0.79126' stroke='white' stroke-width='2'/%3E%0A%3C/svg%3E%0A");
      width: 13px;
      height: 9px;
      background-repeat: no-repeat;
      background-size: 100%;
      display: inline-block;
      margin-left: 10px;
      position: relative;
      top: -2px;
    }

    &_active {
      opacity: 1 !important;

      &:after {
        content: '';
      }
    }
  }
}