aside {
  display: inline-block;
  width: 285px;

  @media (max-width: 1400px) {
    position: fixed;
    top: 80px;
    left: 0;
    width: 100%;
    z-index: 10;
    overflow-y: auto;
    height: calc(100vh - 80px);
    transform: translateX(-100%);
    transition: 0.2s ease transform;
  }

  &.shown {
    transform: translateX(0);
  }

  .nav {
    background: rgba(29, 33, 41, 0.3);
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
    border-radius: 20px;
    padding: 30px 25px 10px 25px;

    @media (max-width: 1400px) {
      border-radius: 20px 20px 0 0;
      height: calc(100vh - 80px);
    }

    .devider {
      border: 1px solid rgba(255, 255, 255, 0.3);
      margin: 20px 0;
      @media (max-width: 1400px) {
        margin: 15px 0;
      }
    }

    .menu {
      display: flex;
      flex-direction: column;
      gap: 15px;
      list-style: none;
      padding: 0;

      &__item {
        display: flex;
        align-items: center;
        border-radius: 10px;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        padding: 16px 16px 16px 27px;
        cursor: pointer;
        background: #212a33;
        transition: 0.2s ease background-color;
        // background: #101b29;
        // border: 1px solid white;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &:last-child {
          margin-top: 35px;
          @media (max-width: 1400px) {
            margin-top: 0;
          }
        }

        &:hover {
          background-color: #28323d;
        }

        &_active {
          background: linear-gradient(90deg, #234a83 0.01%, #922f9b 100%), #27323e;
        }

        &_danger {
          background: #c03838;

          &:hover {
            background: #e64343;
          }
        }

        svg {
          margin-right: 15px;
        }
      }
    }
  }
}
