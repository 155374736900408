.text {
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;

  &_sm {
    font-size: 14px;
    line-height: 16px;
  }

  &_md {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }

  &_lg {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
  }

  &_xl {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
  }

  &-muted {
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 5px;

    &_lg {
      font-size: 20px;
      line-height: 24px;
    }
  }

  &-success {
    color: #57ce3a;
  }

  &-warning {
    color: #fab42f;
  }

  &-error {
    color: #e64343;
  }

  &-primary {
    color: #54A6F1;
  }
}
