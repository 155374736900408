.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.gap-20 {
  gap: 20px;
}

.gap-25 {
  gap: 25px;
}

.gap-30 {
  gap: 30px;
}

.gap-40 {
  gap: 40px;
}

.no-border {
  border: none;
}

.w-50 {
  width: 50%;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}
