.btn {
  padding: 12px 44px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font: 600 14px/17px 'Gilroy', sans-serif;
  background-color: #212a33;
  color: #fff;
  transition: 0.2s ease background-color;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    background-color: #2d3a47;
  }

  &:disabled {
    background-color: #1d2129;
    color: rgba(255, 255, 255, 0.3);
    cursor: default;
    box-shadow: 3px 3px 3px rgb(0 0 0 / 32%);
    font-size: 16px;

    &:hover {
      background-color: #1d2129;
    }
  }

  &_sm {
    padding: 12px 25px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }

  &_minimal {
    padding: 10px 15px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  }

  &-primary {
    background-color: #2d3a47;

    &:hover {
      background-color: #3d4f60;
    }
  }

  &-success {
    background: #67ab53;
    transition: 0.6s all;
    box-shadow: 3px 3px 3px rgb(0 0 0 / 32%);
    font-size: 16px;
    &:hover {
      background: #35a115;
    }
  }

  &-warning {
    font-size: 16px;
    background: #fab42f;
    transition: 0.6s all;
    box-shadow: 3px 3px 3px rgb(0 0 0 / 32%);
    &:hover {
      background: #e39500;
    }
  }

  &-blue {
    background: #54a6f1;

    &:hover {
      background: #72bafb;
    }
  }

  &-danger {
    background: #c03838;

    &:hover {
      background: #cb4949;
    }
  }

  &_add {
    width: 40px;
    height: 40px;
    padding: 10px;
  }

  &-innactive {
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: bold;
    line-height: 16px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &_success {
      background: rgba(116, 205, 93, 0.8);
    }

    &_error {
      background: #c03838;
    }

    &_draft {
      background: #9e9e9e;
    }
  }

  &-xs {
    padding: 8px 10px;
  }
}

#withdraw-modal-open {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  margin-left: 90px;
}

.change-visibility {
  background: transparent;
  border: none;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
  padding: 10px;
  opacity: 0.5;
  transition: 0.2s ease opacity;

  &:hover {
    opacity: 1;
  }
}
