.card {
  background: linear-gradient(109.35deg, #3e6eeb 0%, #7328d3 100%);
  border-radius: 10px;
  padding: 18px 30px;

  &_light {
    background: linear-gradient(109.35deg, #714ae0 0%, #d35b28 100%);
  }

  &__title {
    margin-bottom: 20px;
  }

  &__text {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    text-shadow: 1px 2px 1px black;
  }

  &__skeleton {
    position: relative;

    &::before {
      position: absolute;
      content: '';
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: 10;
      border-radius: 10px;

      background: linear-gradient(90deg, #cb5a38, #d85ee1, #cc5935);

      animation: skeleton 1s infinite reverse;
      background-size: 200%;
    }

    &_blue {
      position: relative;

      &::before {
        position: absolute;
        content: '';
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 10;
        border-radius: 10px;
        background: linear-gradient(90deg, #702bd4, #6c84ff, #6e2ed5);
        animation: skeleton 1s infinite reverse;
        background-size: 200%;
      }
    }
  }
}

@keyframes skeleton {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

.card-block {
  background: rgba(33, 42, 51, 0.5);
  border-radius: 20px;
  padding: 18px 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .custom-input {
    margin-top: 0;
  }
}
