table.table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;

  &.no-border {
    tbody {
      td,
      td {
        border: none;
      }
    }
  }

  thead {
    th {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: rgba(255, 255, 255, 0.5);
    }
  }

  tbody {
    td {
      padding: 15px 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      word-break: break-all;
      font-size: 18px;
      line-height: 19px;
      text-shadow: 1px 1px 1px black;

      &:not(:last-child) {
        padding-right: 30px;
      }

      @media (max-width: 992px) {
        padding-right: 5px !important;
        font-size: 16px;
        line-height: 17px;
      }

      .td-currency {
        display: flex;
        gap: 15px;
        align-items: center;

        @media (max-width: 992px) {
          // flex-direction: column;
          // align-items: flex-end;
        }

        .td-currency__sm {
          font-weight: 700;
          font-size: 12px;
          line-height: 15px;
          color: rgba(255, 255, 255, 0.5);
          margin-top: 5px;
        }
      }

      input[type='checkbox'] {
        width: 20px;
        height: 20px;
      }
    }
  }
}

table.fixedtable {
  width: 100%;
  text-align: left;
  border-collapse: collapse;

  &.no-border {
    tbody {
      td,
      td {
        border: none;
      }
    }
  }

  thead {
    th {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: rgba(255, 255, 255, 0.5);
    }
  }

  tbody {
    td {
      padding: 15px 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      word-break: break-all;
      font-size: 18px;
      line-height: 19px;
      text-shadow: 1px 1px 1px black;

      &:not(:last-child) {
        padding-right: 10px;
      }

      @media (max-width: 992px) {
        padding-right: 10px !important;
        min-height: 50px;
      }

      .td-currency {
        display: flex;
        gap: 15px;
        align-items: center;

        @media (max-width: 992px) {
          flex-direction: column;
          align-items: flex-end;
        }

        .td-currency__sm {
          font-weight: 700;
          font-size: 12px;
          line-height: 15px;
          color: rgba(255, 255, 255, 0.5);
          margin-top: 5px;
        }
      }

      input[type='checkbox'] {
        width: 20px;
        height: 20px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  table.fixedtable {
    border: 0;
  }

  table.fixedtable caption {
    font-size: 1.3em;
  }

  table.fixedtable thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table.fixedtable tr {
    
    display: block;
    margin-bottom: 5px;

    &:not(:last-child) {
      // padding-right: 30px;
      border-bottom: 1px solid #ddd;
    }
  }

  table.fixedtable td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  table.fixedtable td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table.fixedtable td:last-child {
    border-bottom: 0 !important;
    padding: 25px 0 !important;
  }
}
