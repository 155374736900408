*,
*::before,
*::after {
  box-sizing: border-box;
}

@font-face {
  font-family: "Gilroy";
  font-weight: 400;
  src: url(../../assets/fonts/Gilroy-Regular.ttf);
}
@font-face {
  font-family: "Gilroy";
  font-weight: 500;
  src: url(../../assets/fonts/Gilroy-Medium.ttf);
}
@font-face {
  font-family: "Gilroy";
  font-weight: 600;
  src: url(../../assets/fonts/Gilroy-SemiBold.ttf);
}
@font-face {
  font-family: "Gilroy";
  font-weight: 700;
  src: url(../../assets/fonts/Gilroy-Bold.ttf);
}
.text {
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
}
.text_sm {
  font-size: 14px;
  line-height: 16px;
}
.text_md {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.text_lg {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
}
.text_xl {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
}
.text-muted {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 5px;
}
.text-muted_lg {
  font-size: 20px;
  line-height: 24px;
}
.text-success {
  color: #57ce3a;
}
.text-warning {
  color: #fab42f;
}
.text-error {
  color: #e64343;
}
.text-primary {
  color: #54A6F1;
}

.btn {
  padding: 12px 44px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font: 600 14px/17px "Gilroy", sans-serif;
  background-color: #212a33;
  color: #fff;
  transition: 0.2s ease background-color;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.btn:hover {
  background-color: #2d3a47;
}
.btn:disabled {
  background-color: #1d2129;
  color: rgba(255, 255, 255, 0.3);
  cursor: default;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.32);
  font-size: 16px;
}
.btn:disabled:hover {
  background-color: #1d2129;
}
.btn_sm {
  padding: 12px 25px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}
.btn_minimal {
  padding: 10px 15px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}
.btn-primary {
  background-color: #2d3a47;
}
.btn-primary:hover {
  background-color: #3d4f60;
}
.btn-success {
  background: #67ab53;
  transition: 0.6s all;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.32);
  font-size: 16px;
}
.btn-success:hover {
  background: #35a115;
}
.btn-warning {
  font-size: 16px;
  background: #fab42f;
  transition: 0.6s all;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.32);
}
.btn-warning:hover {
  background: #e39500;
}
.btn-blue {
  background: #54a6f1;
}
.btn-blue:hover {
  background: #72bafb;
}
.btn-danger {
  background: #c03838;
}
.btn-danger:hover {
  background: #cb4949;
}
.btn_add {
  width: 40px;
  height: 40px;
  padding: 10px;
}
.btn-innactive {
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 15px;
  font-weight: bold;
  line-height: 16px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.btn-innactive_success {
  background: rgba(116, 205, 93, 0.8);
}
.btn-innactive_error {
  background: #c03838;
}
.btn-innactive_draft {
  background: #9e9e9e;
}
.btn-xs {
  padding: 8px 10px;
}

#withdraw-modal-open {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  margin-left: 90px;
}

.change-visibility {
  background: transparent;
  border: none;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
  padding: 10px;
  opacity: 0.5;
  transition: 0.2s ease opacity;
}
.change-visibility:hover {
  opacity: 1;
}

.card {
  background: linear-gradient(109.35deg, #3e6eeb 0%, #7328d3 100%);
  border-radius: 10px;
  padding: 18px 30px;
}
.card_light {
  background: linear-gradient(109.35deg, #714ae0 0%, #d35b28 100%);
}
.card__title {
  margin-bottom: 20px;
}
.card__text {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  text-shadow: 1px 2px 1px black;
}
.card__skeleton {
  position: relative;
}
.card__skeleton::before {
  position: absolute;
  content: "";
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 10;
  border-radius: 10px;
  background: linear-gradient(90deg, #cb5a38, #d85ee1, #cc5935);
  animation: skeleton 1s infinite reverse;
  background-size: 200%;
}
.card__skeleton_blue {
  position: relative;
}
.card__skeleton_blue::before {
  position: absolute;
  content: "";
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 10;
  border-radius: 10px;
  background: linear-gradient(90deg, #702bd4, #6c84ff, #6e2ed5);
  animation: skeleton 1s infinite reverse;
  background-size: 200%;
}

@keyframes skeleton {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}
.card-block {
  background: rgba(33, 42, 51, 0.5);
  border-radius: 20px;
  padding: 18px 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.card-block .custom-input {
  margin-top: 0;
}

.custom-input {
  display: flex;
  flex-direction: column;
  gap: 0px;
  margin-top: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  transition: 0.2s ease border-color;
}
@media (max-width: 475px) {
  .custom-input {
    flex-direction: column;
    gap: 0;
    margin-top: 10px;
  }
}
.custom-input:has(input:focus) {
  border-color: #fff;
}
.custom-input_block {
  flex-direction: column;
  gap: 0;
  position: relative;
}
.custom-input_block label {
  margin-bottom: 5px !important;
}
.custom-input_block input {
  padding: 10px 0 !important;
}
.custom-input_block-view {
  margin-top: 5px;
}
.custom-input_block-btn {
  position: absolute;
  right: 0;
  bottom: 10px;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
}
.custom-input input {
  flex: 2;
  padding-top: 10px;
  padding-bottom: 10px;
  background: transparent;
  border: none;
  outline: none;
  font-family: "Gilroy", sans-serif;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}
.custom-input input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.custom-input input:-webkit-autofill, .custom-input input:-webkit-autofill:hover, .custom-input input:-webkit-autofill:focus, .custom-input input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #262b35 inset;
  -webkit-text-fill-color: #fff;
  padding-left: 10px !important;
  margin-top: 5px;
}
@media (max-width: 475px) {
  .custom-input input {
    padding: 10px 0;
  }
}
.custom-input select {
  flex: 2;
  padding-top: 10px;
  padding-bottom: 10px;
  background: transparent;
  border: none;
  outline: none;
  font-family: "Gilroy", sans-serif;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}
.custom-input select::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.custom-input select:-webkit-autofill, .custom-input select:-webkit-autofill:hover, .custom-input select:-webkit-autofill:focus, .custom-input select:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #262b35 inset;
  -webkit-text-fill-color: #fff;
  padding-left: 10px !important;
  margin-top: 5px;
}
@media (max-width: 475px) {
  .custom-input select {
    padding: 10px 0;
  }
}
.custom-input label {
  margin: 10px 0;
}

.custom-checkbox {
  width: 20px;
  height: 20px;
  position: relative;
  display: inline-block;
  border: 2px solid #fff;
}
.custom-checkbox:after {
  content: "";
  position: absolute;
  left: 2px;
  top: 4px;
  width: 12px;
  height: 10px;
  opacity: 0;
  transition: 0.1s ease opacity;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='10' viewBox='0 0 12 10' fill='none' %3E%0A%3Cpath d='M4.33317 9.08752L0.166504 4.95419L1.4915 3.67086L4.33317 6.46252L10.5082 0.337524L11.8332 1.65419L4.33317 9.08752Z' fill='white'/%3E%0A%3C/svg%3E%0A");
}
.custom-checkbox:has(input:checked):after {
  opacity: 1;
}
.custom-checkbox input {
  display: none;
}

.custom-switch {
  display: flex;
}
.custom-switch input {
  display: none;
}
.custom-switch input:checked + label::before {
  background: rgba(116, 205, 93, 0.8);
}
.custom-switch input:checked + label::after {
  transform: translateX(20px);
}
.custom-switch label {
  cursor: pointer;
  position: relative;
}
.custom-switch label::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
  background-color: #c03838;
  border-radius: 50px;
  width: 50px;
  height: 30px;
  transition: 0.2s ease background-color;
}
.custom-switch label::after {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  background: #ffffff;
  border-radius: 50px;
  left: 3px;
  top: 3px;
  transition: 0.2s ease transform;
}

.select-btn {
  font: 500 16px/19px "Gilroy", sans-serif;
  color: #fff;
  position: relative;
  opacity: 0.5;
  cursor: pointer;
  transition: 0.2s ease opacity;
  border: none;
  background: transparent;
  text-align: left;
  width: 200px;
  padding: 0;
  margin-top: 15px;
}
.select-btn:hover {
  opacity: 1;
}
.select-btn:after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='10' viewBox='0 0 15 10' fill='none' %3E%0A%3Cpath d='M1.66211 1.37708L7.91602 7.63123L14.1702 1.37708' stroke='white' stroke-width='2'/%3E%0A%3C/svg%3E%0A");
  width: 15px;
  height: 10px;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  margin-left: 10px;
  position: relative;
  top: -2px;
}

.select-options {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 30px 0 0;
  padding: 0;
  list-style: none;
  font: 500 16px/19px "Gilroy", sans-serif;
  color: #fff;
}
.select-options .select-option {
  position: relative;
  opacity: 0.5;
  cursor: pointer;
  transition: 0.2s ease opacity;
}
.select-options .select-option:hover {
  opacity: 0.8;
}
.select-options .select-option:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='11' viewBox='0 0 16 11' fill='none' %3E%0A%3Cpath d='M1.31152 3.74737L6.76855 9.20452L15.1818 0.79126' stroke='white' stroke-width='2'/%3E%0A%3C/svg%3E%0A");
  width: 13px;
  height: 9px;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  margin-left: 10px;
  position: relative;
  top: -2px;
}
.select-options .select-option_active {
  opacity: 1 !important;
}
.select-options .select-option_active:after {
  content: "";
}

table.table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
table.table.no-border tbody td,
table.table.no-border tbody td {
  border: none;
}
table.table thead th {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.5);
}
table.table tbody td {
  padding: 15px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  word-break: break-all;
  font-size: 18px;
  line-height: 19px;
  text-shadow: 1px 1px 1px black;
}
table.table tbody td:not(:last-child) {
  padding-right: 30px;
}
@media (max-width: 992px) {
  table.table tbody td {
    padding-right: 5px !important;
    font-size: 16px;
    line-height: 17px;
  }
}
table.table tbody td .td-currency {
  display: flex;
  gap: 15px;
  align-items: center;
}
table.table tbody td .td-currency .td-currency__sm {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 5px;
}
table.table tbody td input[type=checkbox] {
  width: 20px;
  height: 20px;
}

table.fixedtable {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
table.fixedtable.no-border tbody td,
table.fixedtable.no-border tbody td {
  border: none;
}
table.fixedtable thead th {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.5);
}
table.fixedtable tbody td {
  padding: 15px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  word-break: break-all;
  font-size: 18px;
  line-height: 19px;
  text-shadow: 1px 1px 1px black;
}
table.fixedtable tbody td:not(:last-child) {
  padding-right: 10px;
}
@media (max-width: 992px) {
  table.fixedtable tbody td {
    padding-right: 10px !important;
    min-height: 50px;
  }
}
table.fixedtable tbody td .td-currency {
  display: flex;
  gap: 15px;
  align-items: center;
}
@media (max-width: 992px) {
  table.fixedtable tbody td .td-currency {
    flex-direction: column;
    align-items: flex-end;
  }
}
table.fixedtable tbody td .td-currency .td-currency__sm {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 5px;
}
table.fixedtable tbody td input[type=checkbox] {
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 992px) {
  table.fixedtable {
    border: 0;
  }
  table.fixedtable caption {
    font-size: 1.3em;
  }
  table.fixedtable thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  table.fixedtable tr {
    display: block;
    margin-bottom: 5px;
  }
  table.fixedtable tr:not(:last-child) {
    border-bottom: 1px solid #ddd;
  }
  table.fixedtable td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }
  table.fixedtable td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  table.fixedtable td:last-child {
    border-bottom: 0 !important;
    padding: 25px 0 !important;
  }
}
.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.gap-20 {
  gap: 20px;
}

.gap-25 {
  gap: 25px;
}

.gap-30 {
  gap: 30px;
}

.gap-40 {
  gap: 40px;
}

.no-border {
  border: none;
}

.w-50 {
  width: 50%;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

header {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 70px;
}
header .contact-us {
  display: flex;
  align-items: center;
  gap: 15px;
  color: #fff;
  text-decoration: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (max-width: 992px) {
  header .contact-us {
    display: none;
  }
}
header .logo {
  width: 285px;
  font: 700 48px/58px "Gilroy", sans-serif;
  text-align: center;
}
@media (max-width: 1400px) {
  header .logo {
    width: auto;
    margin-left: 50px;
  }
}
header #menu__toggle {
  display: none;
}
header #menu__toggle:checked + .menu__btn > span {
  transform: rotate(45deg);
}
header #menu__toggle:checked + .menu__btn > span::before {
  top: 0;
  transform: rotate(0deg);
}
header #menu__toggle:checked + .menu__btn > span::after {
  top: 0;
  transform: rotate(90deg);
}
header .menu__btn {
  position: absolute;
  top: 43px;
  left: 25px;
  width: 26px;
  height: 20px;
  cursor: pointer;
  z-index: 1;
  padding-top: 10px;
}
@media (min-width: 1400px) {
  header .menu__btn {
    display: none;
  }
}
header .menu__btn > span,
header .menu__btn > span::before,
header .menu__btn > span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #fff;
  transition-duration: 0.25s;
}
header .menu__btn > span::before {
  content: "";
  top: -8px;
}
header .menu__btn > span::after {
  content: "";
  top: 8px;
}

aside {
  display: inline-block;
  width: 285px;
}
@media (max-width: 1400px) {
  aside {
    position: fixed;
    top: 80px;
    left: 0;
    width: 100%;
    z-index: 10;
    overflow-y: auto;
    height: calc(100vh - 80px);
    transform: translateX(-100%);
    transition: 0.2s ease transform;
  }
}
aside.shown {
  transform: translateX(0);
}
aside .nav {
  background: rgba(29, 33, 41, 0.3);
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  border-radius: 20px;
  padding: 30px 25px 10px 25px;
}
@media (max-width: 1400px) {
  aside .nav {
    border-radius: 20px 20px 0 0;
    height: calc(100vh - 80px);
  }
}
aside .nav .devider {
  border: 1px solid rgba(255, 255, 255, 0.3);
  margin: 20px 0;
}
@media (max-width: 1400px) {
  aside .nav .devider {
    margin: 15px 0;
  }
}
aside .nav .menu {
  display: flex;
  flex-direction: column;
  gap: 15px;
  list-style: none;
  padding: 0;
}
aside .nav .menu__item {
  display: flex;
  align-items: center;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  padding: 16px 16px 16px 27px;
  cursor: pointer;
  background: #212a33;
  transition: 0.2s ease background-color;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
aside .nav .menu__item:last-child {
  margin-top: 35px;
}
@media (max-width: 1400px) {
  aside .nav .menu__item:last-child {
    margin-top: 0;
  }
}
aside .nav .menu__item:hover {
  background-color: #28323d;
}
aside .nav .menu__item_active {
  background: linear-gradient(90deg, #234a83 0.01%, #922f9b 100%), #27323e;
}
aside .nav .menu__item_danger {
  background: #c03838;
}
aside .nav .menu__item_danger:hover {
  background: #e64343;
}
aside .nav .menu__item svg {
  margin-right: 15px;
}

.main {
  display: inline-block;
  margin-left: 70px;
  margin-bottom: 70px;
  vertical-align: top;
  width: calc(100% - 360px);
}
@media (max-width: 1400px) {
  .main {
    margin-left: 0;
    width: 100%;
  }
}
.main__head {
  display: flex;
  align-items: center;
  gap: 25px;
  margin-bottom: 45px;
}
@media (max-width: 460px) {
  .main__head {
    flex-direction: column-reverse;
    align-items: start;
    gap: 10px;
    margin-bottom: 20px;
  }
}
.main__head-back {
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.main__head-back:hover svg {
  transform: translateX(-2px);
}
.main__head-back svg {
  margin-right: 10px;
  transition: 0.2s ease transform;
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 15;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  transition: 0.2s ease visibility, 0.2s ease opacity;
}
.modal.shown {
  opacity: 1;
  visibility: visible;
  pointer-events: visible;
}
.modal.shown .dialog {
  transform: translate(0);
}
.modal .dialog {
  max-width: 600px;
  margin: 150px auto;
  padding: 30px 40px 60px;
  background: #1D2129;
  border-radius: 15px;
  transform: translateY(-10px);
  transition: 0.2s ease transform;
}
@media (max-width: 475px) {
  .modal .dialog {
    padding: 30px 30px 40px;
  }
}
.modal .dialog__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.modal .dialog__head-title {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}
.modal .dialog__head-close {
  cursor: pointer;
}
.modal .dialog__head-close:hover svg {
  transform: translateX(-2px);
}
.modal .dialog__head-close svg {
  margin-right: 10px;
  transition: 0.2s ease transform;
}
.modal .dialog__head-action {
  cursor: pointer;
}
@media (max-width: 475px) {
  .modal .dialog__body {
    padding-bottom: 30px;
  }
}
.modal-select .dialog {
  max-width: 300px;
  margin: 160px auto;
}

.projects {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.projects .project {
  background-color: #212a33;
  background-image: url("../../assets/img/card1.png");
  background-size: cover;
  padding: 20px 25px;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 10px;
}
@media (max-width: 1400px) {
  .projects .project {
    padding: 10px 15px;
  }
}
.projects .project_success {
  border: 2px solid rgba(116, 205, 93, 0.5);
}
.projects .project_warning {
  border: 2px solid rgba(250, 180, 47, 0.6);
  background-image: url("../../assets/img/card2.png");
}
.projects .project_error {
  border: 2px solid rgba(230, 67, 67, 0.6);
}
.projects .project__title {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 25px;
}
@media (max-width: 420px) {
  .projects .project__title {
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }
}
.projects .project__devider {
  border: 1px solid rgba(255, 255, 255, 0.3);
  height: 90px;
  margin: 0 30px 0 40px;
}
@media (max-width: 1400px) {
  .projects .project__devider {
    display: none;
  }
}
.projects .project__section {
  flex-shrink: 0;
}
@media (max-width: 420px) {
  .projects .project__section {
    flex-shrink: initial;
    width: 100%;
  }
}
@media (max-width: 1400px) {
  .projects .project__section:last-child {
    display: none;
  }
}
.projects .project__section-gap {
  display: flex;
  gap: 40px;
}
@media (max-width: 400px) {
  .projects .project__section-gap {
    gap: 20px;
  }
}
.projects .project__info {
  display: flex;
  gap: 45px;
}
@media (max-width: 420px) {
  .projects .project__info {
    flex-direction: column;
    gap: 10px;
  }
}

.project-details__tabs {
  display: flex;
  gap: 90px;
}
@media (max-width: 1400px) {
  .project-details__tabs {
    gap: 20px;
    flex-wrap: wrap;
    row-gap: 10px;
  }
}
.project-details__tabs .tab {
  cursor: pointer;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);
  padding-bottom: 10px;
  transition: 0.2s ease color;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.project-details__tabs .tab:hover {
  color: #fff;
}
.project-details__tabs .tab.active {
  color: #fff;
  border-bottom: 1px solid #ffffff;
}
.project-details__stats {
  margin: 40px 0 30px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
@media (max-width: 992px) {
  .project-details__stats {
    flex-direction: column;
  }
}
.project-details__stats .card {
  width: 100%;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3215686275);
}
@media (max-width: 992px) {
  .project-details__stats .card {
    width: 100%;
  }
}
.project-details__table-filter {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.project-details__table-filter.__trader {
  align-items: flex-end;
  gap: 20px;
}
.project-details__table-filter.__trader select {
  min-width: 120px;
}
.project-details__table-filter.__trader button:last-child {
  margin-left: auto;
}
@media (max-width: 992px) {
  .project-details__table-filter {
    flex-direction: column;
    gap: 15px;
    width: 100%;
  }
  .project-details__table-filter.__trader {
    align-items: center;
    gap: 15px;
  }
  .project-details__table-filter.__trader button:last-child {
    margin-left: 0;
  }
}
@media (max-width: 992px) {
  .project-details__table-filter br {
    display: none;
  }
}
.project-details__table-filter input {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  padding: 8px 30px 8px 18px;
  height: 35px;
  border: 1px solid #ffffff;
  color: #fff;
  font: 500 16px/19px "Gilroy", sans-serif;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1);
}
.project-details__table-filter input:focus {
  background-color: #593641;
}
.project-details__table-filter select {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  padding: 8px 30px 8px 18px;
  height: 35px;
  border: 1px solid #ffffff;
  color: #fff;
  font: 500 16px/19px "Gilroy", sans-serif;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1) url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='8' viewBox='0 0 12 8' fill='none' %3E%0A%3Cpath d='M1 1.10962L6 6.10962L11 1.10962' stroke='white' stroke-width='2'/%3E%0A%3C/svg%3E%0A") no-repeat;
  background-position-x: calc(100% - 14px);
  background-position-y: 14px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.project-details__table-filter select:focus {
  background-color: #593641;
}
.project-details__table-filter .input {
  outline: none;
  border: none;
  width: 200px;
  margin-top: 39px;
  border-radius: 0px;
  background: transparent;
  border-bottom: 1px solid #ffffff;
  color: #fff;
  font: 500 16px/19px "Gilroy", sans-serif;
}
.project-details__table-filter .input::placeholder {
  color: #fff;
}
.project-details__table-filter .input:focus {
  border-radius: 5px;
}
@media (max-width: 992px) {
  .project-details__table-filter .input {
    width: 100%;
  }
}
.project-details__tab {
  display: none;
}
.project-details__tab_active {
  display: block;
}
.project-details .project-currencies {
  display: flex;
}
@media (max-width: 992px) {
  .project-details .project-currencies {
    flex-direction: column;
  }
}
.project-details .project-currencies__table {
  width: 100%;
}
@media (max-width: 992px) {
  .project-details .project-currencies__table {
    width: 100%;
  }
}
.project-details .project-currencies .currency-wrapper {
  width: 100%;
  background: rgba(29, 33, 41, 0.3);
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  border-radius: 20px;
  padding: 30px 30px 100px;
}
@media (max-width: 992px) {
  .project-details .project-currencies .currency-wrapper {
    width: 100%;
    margin-top: 30px;
  }
}
.project-details .project-currencies .currency-wrapper .custom-input_block:first-child {
  margin-top: 0;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  gap: 8px;
}
.pagination button {
  width: 32px;
  height: 32px;
  cursor: pointer;
  font: 600 14px/20px "Gilroy", sans-serif;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(223, 227, 232, 0.2);
  border-radius: 4px;
  transition: 0.2s ease background-color;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.pagination button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
.pagination button:disabled {
  cursor: default;
  background-color: rgba(255, 255, 255, 0.2) !important;
}
.pagination button:disabled:before {
  opacity: 0.4;
}
.pagination button:disabled:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.pagination-active {
  background-color: rgba(255, 255, 255, 0.4) !important;
  border-color: #fff !important;
  color: #fff !important;
}
.pagination-prev:before, .pagination-next:before {
  content: "";
  width: 9px;
  height: 13px;
  background-size: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.pagination-disabled {
  cursor: default;
  background-color: rgba(255, 255, 255, 0.2) !important;
}
.pagination-disabled:before {
  opacity: 0.4;
}
.pagination-disabled:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.pagination-prev {
  position: relative;
}
.pagination-prev:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='13' viewBox='0 0 9 13' fill='none' %3E%0A%3Cpath d='M8.15991 1.91415L3.57991 6.50415L8.15991 11.0942L6.74991 12.5042L0.749912 6.50415L6.74991 0.50415L8.15991 1.91415Z' fill='white'/%3E%0A%3C/svg%3E%0A");
}
.pagination-next {
  position: relative;
}
.pagination-next:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='13' viewBox='0 0 9 13' fill='none' %3E%0A%3Cpath d='M0.840088 1.91415L5.42009 6.50415L0.840088 11.0942L2.25009 12.5042L8.25009 6.50415L2.25009 0.50415L0.840088 1.91415Z' fill='white'/%3E%0A%3C/svg%3E%0A");
}

body,
html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  font: 500 18px/21px "Gilroy", sans-serif;
  color: #fff;
}
body::before {
  content: "";
  /* background: #171a21 url('../../assets/img/bg.png'); */
  background-color: #311e29;
  background-image: linear-gradient(43deg, #311e29 0%, #3c242f 20%, #531c30 54%, #47303d 83%, #343542 100%);
  background-size: cover;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}
body.second::before {
  /* background: #171a21 url('../../assets/img/bg2.png'); */
  background-color: #311e29;
  background-image: linear-gradient(43deg, #311e29 0%, #3c242f 20%, #531c30 54%, #47303d 83%, #343542 100%);
  background-size: cover;
}

.container {
  max-width: 1400px;
  padding: 10px 25px;
  margin: 0 auto;
}

.section-about {
  max-width: 500px;
}

.section-settings {
  max-width: 500px;
}

