.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 15;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  transition: .2s ease visibility, .2s ease opacity;

  &.shown {
    opacity: 1;
    visibility: visible;
    pointer-events: visible;

    .dialog {
      transform: translate(0);
    }
  }

  .dialog {
    max-width: 600px;
    margin: 150px auto;
    padding: 30px 40px 60px;
    background: #1D2129;
    border-radius: 15px;
    transform: translateY(-10px);
    transition: .2s ease transform;

    @media (max-width: 475px) {
      padding: 30px 30px 40px;
    }

    &__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      &-title {
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
      }

      &-close {
        cursor: pointer;

        &:hover {
          svg {
            transform: translateX(-2px);
          }
        }
  
        svg {
          margin-right: 10px;
          transition: 0.2s ease transform;
        }
      }

      &-action {
        cursor: pointer;
      }
    }
    &__body {
      @media (max-width: 475px) {
        padding-bottom: 30px;
      }
    }
  }

  &-select {
    .dialog {
      max-width: 300px;
      margin: 160px auto;
    }
  }
}