header {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 70px;

  .contact-us {
    display: flex;
    align-items: center;
    gap: 15px;
    color: #fff;
    text-decoration: none;
    // margin: 43px 0 58px 0;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @media (max-width: 992px) {
      display: none;
    }
  }

  .logo {
    width: 285px;
    font: 700 48px/58px 'Gilroy', sans-serif;
    text-align: center;

    @media (max-width: 1400px) {
      width: auto;
      margin-left: 50px;
    }
  }

  #menu__toggle {
    display: none;
  }
  #menu__toggle:checked + .menu__btn > span {
    transform: rotate(45deg);
  }
  #menu__toggle:checked + .menu__btn > span::before {
    top: 0;
    transform: rotate(0deg);
  }
  #menu__toggle:checked + .menu__btn > span::after {
    top: 0;
    transform: rotate(90deg);
  }

  .menu__btn {
    position: absolute;
    top: 43px;
    left: 25px;
    width: 26px;
    height: 20px;
    cursor: pointer;
    z-index: 1;
    padding-top: 10px;

    @media (min-width: 1400px) {
      display: none;
    }
  }

  .menu__btn > span,
  .menu__btn > span::before,
  .menu__btn > span::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #fff;
    transition-duration: 0.25s;
  }
  .menu__btn > span::before {
    content: '';
    top: -8px;
  }
  .menu__btn > span::after {
    content: '';
    top: 8px;
  }
}
