.toggle-button {
  position: relative;
  width: 60px;
  height: 30px;
  background-color: #c03838;
  border-radius: 15px;
  cursor: pointer;
  outline: none;
  border: none;
}

.toggle-button--toggled {
  background-color: rgba(116, 205, 93, 0.8);
}

.toggle-button__slider {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 24px;
  height: 24px;
  background-color: #ffffff;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.toggle-button--toggled .toggle-button__slider {
  transform: translateX(30px);
}
