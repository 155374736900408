.emptyProgressBar {
  background-color: #e7e4da;
  border-radius: 100px;
  height: 5px;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.fillingProgressBar {
  background-color: rgba(116, 205, 93, 0.8);
  /* background: linear-gradient(90deg, #234a83 0.01%, #922f9b 100%), #27323e; */
  border-radius: 5px;
  height: 100%;
  width: 100%;
  position: absolute;
}

.warningProgressBar {
  background-color: #F00;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  position: absolute;
}

.mainProgressBarContainer {
  margin-top: 3px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
}
